<template>
  <div class="page">
    <infoList
      class="mt-20"
      :titleName="'电池信息'"
      :type="'battery'"
      :dataList="batteryDataList"
    ></infoList>
  </div>
</template>
            
  <script>
import { dataInterface } from "@/api/dataInterfaceApi";
import infoList from "@/views/components/infoList";
export default {
  components: {
    // myEchart,
    infoList,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      batteryDataList: {},
      query: query
    };
  },
  mounted() {
    this.getBatteryInfoList();
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getBatteryInfoList();
      },
    },
  },
  methods: {
    //获取电池信息
    getBatteryInfoList() {
      let params = {
        enCode:
          "per_vehicle-电池编码-生厂商-额定电压-额定容量-质保年限-质保里程-充电电量-累计充电",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          this.batteryDataList = res[0] ? res[0] : {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
            
  <style lang="scss" scoped>
@import "../scss/page.scss";
.map-info-box {
  // height: 100%;
  .map-info-list {
    // overflow: auto;
    // height: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .map-info-box-cell {
      width: 45%;
      padding: 0 10px;
      height: 60px;
      line-height: 60px;
      font-size: 14px;
      // display: flex;
      // align-items: center;
      // line-height: 37px;
      background: rgba(1, 177, 169, 0.3);
      margin: 10px 5px;
      // text-align: center;
      overflow: hidden; //超出隐藏
      text-overflow: ellipsis; //显示省略号
      white-space: nowrap; //强制不换行
    }
    .map-info-box-cell:hover {
      overflow: auto;
    }
  }
}
</style>