<template>
  <div class="model">
    <div class="model-1">
      <p class="address">{{ singleData.city || '-' }}</p>
      <div class="typeBox">
        <div><span>车牌：</span><span>{{ singleData.license_plate || '-' }}</span></div>
        <div><span>状态：</span><span>{{ singleData.vehicle_state || '-' }}</span></div>
        <div><span>车辆VIN码：</span><span>{{ singleData.vehicle_vin || '-' }}</span></div>
        <div><span>品牌型号：</span><span>{{  singleData.vehicle_model || '-' }}</span></div>
      </div>
    </div>
    <img class="line-1" src="@/assets/images/vehiclesType/line-1.png" alt="" />
  </div>
</template>
      
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    //   myEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      singleData: {}, //单车信息位置车牌soc 状态
      query: query,
      timer: null
    };
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getSingleCar();
      },
    },
  },
  mounted() {
    this.getSingleCar()
    this.timer = setInterval(() =>{
      this.getSingleCar()
    },10 * 1000)
  },
  methods: {
    getSingleCar() {
      let params = {
        enCode: "per_vehicle-dcsszb",
        "@vin":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            this.singleData = res[0] ? res[0] : {};
          }
          console.log(res, '车辆信息')
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
      
<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.model {
  width: 617px;
  height: 294px;
  background: url("~@/assets/images/vehiclesType/model-1.png") no-repeat;
  position: relative;
  .model-1 {
    .address {
      padding-top: 10px;
      margin-left: 60px;
      color: #08ffff;
      font-size: $FontSize26;
    }
    .typeBox {
      padding-top: 30px;
      margin-left: 60px;
      font-size: $FontSize24;
      div {
        padding: 8px 0;
      }
    }
  }
  .line-1 {
    position: absolute;
    bottom: -50px;
    left: -134px;
  }
}
</style>