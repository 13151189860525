<template>
  <div class="page">
    <MyEchart :visible="showcdata2" :id="'averageEleMile'" :options="cdata2" />
  </div>
</template>
      
      <script>
import MyEchart from '@/views/components/charts/echarts'
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      showcdata2: false,
      // title:"平均度电里程",
      cdata2: {
        title: {
          text: "km/度",
        },
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          splitLine: false,
        },
        legendData: [
          {
            name: "新增数",
            icon: "roundRect",
          },
          {
            name: "活跃数",
            icon: "roundRect",
          },
        ],
        series: [
          {
            // name: "平均度电里程",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "#87F6FD", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(135, 246, 253,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            itemStyle: {
              normal: {
                color: "#87F6FD", //改变折线点的颜色
              },
            },
            lineStyle: {
                width: 2,
                color: "#87F6FD",
              },
          },
        ],
      },
      query: query,
    };
  },
  mounted() {
    this.getBattery2();
  },
  methods: {
    //获取平均度电里程
    getBattery2() {
      let params = {
        enCode: "per_vehicle-平均度电里程",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            let data = [];
            let xAxis = [];
            res.forEach((item) => {
              xAxis.push(item.cur_date);
              data.push(item.mileage_per_kwh || 0);
            });
            this.cdata2.xAxis.data = xAxis;
            this.cdata2.series[0].data = data;
            this.showcdata2 = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
      
      <style lang="scss" scoped>
@import "../scss/page.scss";
</style>