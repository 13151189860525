<template>
  <div class="page">
    <MyEchart :visible="showcdata1" :id="'averageUsePower'" :options="cdata1" />
  </div>
</template>
    
    <script>
import MyEchart from '@/views/components/charts/echarts'
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      showcdata1: false,
      // title:"平均能耗趋势",
      cdata1: {
        title: {
          text: "度/km",
        },
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
          splitLine: false,
        },
        // legendData: [
        //   {
        //     name: "新增数",
        //     icon: "roundRect",
        //   },
        //   {
        //     name: "活跃数",
        //     icon: "roundRect",
        //   },
        // ],
        series: [
          {
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "#2A5CEC", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(42, 92, 236,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },

            lineStyle: {
                width: 2,
                color: "#3689E3",
              },
          },
        ],
      },
      query: query
    };
  },
  mounted() {
    this.getBattery1();
  },
  methods: {
    //获取平均能耗趋势
    getBattery1() {
      let params = {
        enCode: "per_vehicle-平均度电能耗",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            let data = [];
            let xAxis = [];
            res.forEach((item) => {
              xAxis.push(item.cur_date);
              data.push(item.energy_consuption_per_mileage || 0);
            });
            this.cdata1.xAxis.data = xAxis;
            this.cdata1.series[0].data = data;
            this.showcdata1 = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../scss/page.scss";
</style>