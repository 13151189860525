<template>
  <div class="page flex flex-direction justify-content align-items mt-50">
    <div class="sohDiv">
      <img
        class="radioImg"
        src="~@/assets/images/exposition/chucunshengyudianliang.png"
        alt=""
      />
      <div class="soh">{{ Number(soh).toFixed(2) }}%</div>
      <!-- <animate-number
            from="0"
            :to="Number(soh).toFixed(2)"
            :key="Number(soh).toFixed(2)"
            class="soh"
          ></animate-number>% -->
    </div>
    <div class="value">电池健康度</div>
  </div>
</template>
          
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    // myEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      soh: 0,
      query: query
    };
  },
  mounted() {
    this.getBatteryHealthy();
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getBatteryHealthy();
      },
    },
  },
  methods: {
    //获取电池健康度
    getBatteryHealthy() {
      let params = {
        enCode: "per_vehicle-电池健康度",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            console.log(res, "电池健康度");
            this.soh = res[0].soh * 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
          
<style lang="scss" scoped>
@import "../scss/page.scss";
.page {
  .sohDiv {
    position: relative;
    .radioImg {
      width: 356px;
      height: 356px;
    }
    .soh {
      position: absolute;
      top: 40%;
      left: 26%;
    // top: 0;
    // left: 0;
    //   transform: (-50%, -50%);
      font-size: $FontSize45;
      @include ffamily-HT;
    }
  }
  .value {
    font-size: $FontSize36;
  }
}
</style>