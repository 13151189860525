<template>
  <div class="typeInfoBox flex">
    <div class="typeInfo flex" v-for="(item, index) in list" :key="'list'+index">
      <div>
        <img
          class="itemImg"
          :src="require(`@/assets/images/vehiclesType/type-${index+1}.png`)"
          alt=""
        />
      </div>
      <div class="typeInfo-left">
        <div class="text">
          <span>{{item.name}}</span>
        </div>
        <div class="text-bottom">
          <span>{{ item.number  }}</span>
          <!-- <animate-number
            from="0"
            :to="item.number"
            :key="item.number"
            class=""
          ></animate-number> -->
          <span class="uuid">{{ item.uuid }}</span>
        </div>
      </div>
    </div>

  </div>
</template>
      
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route;
    return {
      satationData: {},
      query: query,
      list: [
        {
          name: '累计放电电量',
          number : 0,
          uuid: 'kWh'
        },
        {
          name: '累计行驶里程',
          number : 0,
          uuid: 'km'
        }
        ,
        {
          name: '累计碳减排',
          number : 0,
          uuid: 'kg'
        }
      ],
      timer: null
    };
  },
  mounted() {
    this.getOperational();
    this.timer = setInterval(() =>{
      this.getOperational();
    },10 * 1000)
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getOperational();
      },
    },
  },
  methods: {
    // 获取运营指标
    getOperational() {
      let params = {
        enCode: "per_vehicle-dcsszb",
        "@vin": this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            console.log(res, "运营指标");
            this.satationData = res[0] || {};
            this.list[0].number = Number(res[0].discharge_power_sum).toFixed(0) 
            this.list[1].number = Number(res[0].mileage).toFixed(0) 
            this.list[2].number = Number(res[0].carbon_emissions_sum).toFixed(0) 
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
      
<style lang="scss" scoped>
@import "../scss/page.scss";
@import "../scss/page.scss";
.typeInfoBox {
  width: 100%;
  height: 100%;
  .typeInfo {
    width: calc(100% / 3);
    height: 218px;
    // background: orange;
    .itemImg {
      width: 208px;
      height: 218px;
      // animation: breath_light 3s ease infinite;
    }
    .typeInfo-left {
      flex: 1;
      .text {
        width: 100%;
        height: 50%;
        // background: red;
        position: relative;
        span {
          position: absolute;
          bottom: 0;
          display: block;
          width: 306px;
          height: 47px;
          line-height: 38px;
          background: url("~@/assets/images/vehiclesType/text-bg.png") no-repeat;
          background-size: 100% 100%;
          font-size: $FontSize36;
          padding-left: 43px;
        }
      }
      .text-bottom {
        padding-left: 37px;
        width: 100%;
        height: 50%;
        // background: skyblue;
        @include ffamily-HT;
        span {
          font-size: $FontSize60;
          color: #0efdc6;
        }
        .uuid {
          font-size: $FontSize30;
          color: #fff;
        }
      }
    }
  }
}
</style>