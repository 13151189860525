<template>
  <div class="page">
    <MyEchart :visible="showcdata1" :id="'changeNumber'" :options="cdata1" />
  </div>
</template>
    
    <script>
import MyEchart from "@/views/components/charts/echarts";
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  props: {
    vin: {
      type: String,
      default: "LMEWWG2R2NEK00047",
    },
  },
  data() {
    let { query } = this.$route;
    return {
      //  充换电次数
      showcdata1: false,
      cdata1: {
        title: {
          text: "次",
        },
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        legend: {
          right: "left",
          textStyle: {
            color: "#C6D1DB",
            fontSize: 18, //
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          splitLine: false,
        },
        series: [],
      },
      query: query,
    };
  },
  mounted() {
    this.getBattery1();
    // this.$bus.$on('vin', (vin) => {
    //   this.vin = vin;
    //   this.getBattery1()
    // });
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
      },
    },
  },
  methods: {
    //充换电次数
    getBattery1() {
      let params = {
        enCode: "per_vehicle-充换电次数",
        "@VEHICLE_VIN": this.query.newVehicleNum || this.vin,
      };
      console.log(params, "新的vin");
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            let data1 = [];
            let data2 = [];
            let xAxis = [];
            res.forEach((item) => {
              xAxis.push(item.cur_date);
              data1.push(item.daily_exchange_num);
              data2.push(item.daily_charge_num);
            });
            let series1 = {
              name: "换电次数",
              type: "line",
              // stack: 'Total',
              smooth: true,
              areaStyle: {
                normal: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0.1,
                        color: "#11518D", // 0% 处的颜色
                      },
                      {
                        offset: 0.9,
                        color: "rgba(17, 81, 141,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
              itemStyle: {
                normal: {
                  color: "#11C1E3",
                },
              },
              showSymbol: false,
            };
            let series2 = {
              name: "充电次数",
              type: "line",
              // stack: 'Total',
              smooth: true,
              itemStyle: {
                normal: {
                  color: "#34CE84",
                },
              },
              showSymbol: false,
              areaStyle: {
                normal: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0.1,
                        color: "#298E66", // 0% 处的颜色
                      },
                      {
                        offset: 0.9,
                        color: "rgba(41, 142, 102,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            };
            this.cdata1.xAxis.data = xAxis;
            series1.data = data1;
            this.cdata1.series.push(series1);
            series2.data = data2;
            this.cdata1.series.push(series2);
            this.showcdata1 = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../scss/page.scss";
</style>