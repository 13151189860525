<template>
  <div class="page">
    <MyEchart :visible="showcdata2" :id="'SOCValueRange'" :options="cdata2" />
  </div>
</template>
    
    <script>
import MyEchart from '@/views/components/charts/echarts'
// import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      showcdata2: false,
      // title:"车辆新增及活跃趋势",
      cdata2: {
        title: {
          text: "%",
        },
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        xAxis: {
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          splitLine: false,
        },
        legend: {
            // top: "50%",
            // bottom: 0,
            left: "right",
            orient: "vertical",
            textStyle: {
              color: "#fff",
            },
          },
        series: [
          {
            // name: "SOC值",
            type: "line",
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "#0057FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(0, 87, 255,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
            lineStyle: {
              color: "#0057FF", //改变折线颜色
            },
          },
        ],
      },
      query: query,
    };
  },
  mounted() {
    this.getBatterySOC();
  },
  methods: {
    //获取SOC
    getBatterySOC() {
      let params = {
        enCode: "per_vehicle-SOC值区间",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            // console.log(res, 'shuijoidssdijosdijo')
            let data = [];
            let xAxis = [];
            res.forEach((item) => {

              xAxis.push(item.cur_date);
              data.push(item.avg_soc || 0);
              // xAxis.push(item.down_soc_range)
              // data.push(item.exchange_num)
            });
            this.cdata2.xAxis.data = xAxis;
            this.cdata2.series[0].data = data;
            this.showcdata2 = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    <style lang="scss" scoped>
@import "../scss/page.scss";
</style>