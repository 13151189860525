<template>
  <div>
    <div class="map-info-box" v-if="type === 'car'">
      <!-- <Title :title="titleName"></Title> -->
      <div class="map-info-list">
        <div class="map-info-box-cell">VIN码: {{ dataList.vehicle_vin }}</div>
        <div class="map-info-box-cell">
          车牌号: {{ dataList.license_plate }}
        </div>
        <div class="map-info-box-cell">
          品牌型号: {{ dataList.vehicle_model }}
        </div>
        <div class="map-info-box-cell">
          运营商: {{ dataList.gcl_operating_company }}
        </div>
      </div>
    </div>
    <div class="map-info-box" v-if="type === 'battery'">
      <!-- <Title :title="titleName"></Title> -->
      <div class="map-info-list">
        <div class="map-info-box-cell" :title="dataList.battery_id">
          编码: {{ dataList.battery_id }}
        </div>
        <div class="map-info-box-cell" :title="dataList.manufacturer_name">
          生厂商: {{ dataList.manufacturer_name }}
        </div>
        <div class="map-info-box-cell" :title="dataList.rated_voltage">
          额定电压（V）: {{ dataList.rated_voltage }}
        </div>
        <div class="map-info-box-cell" :title="dataList.rated_total_amount">
          额定容量（Ah）: {{ dataList.rated_total_amount }}
        </div>
        <div class="map-info-box-cell" :title="dataList.warranty_period">
          质保年限: {{ dataList.warranty_period }}
        </div>
        <div class="map-info-box-cell" :title="dataList.warranty_mileage">
          质保里程（km）: {{ dataList.warranty_mileage }}
        </div>
        <div class="map-info-box-cell" :title="dataList.charge_elect">
          充电量（度）: {{ dataList.charge_elect }}
        </div>
        <div class="map-info-box-cell" :title="dataList.acc_charge_num">
          累计充电次数: {{ dataList.acc_charge_num }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Title from '../title.vue'
export default {
  data() {
    return {};
  },
  components: {
    // Title
  },
  props: {
    titleName: {
      type: String,
      default: "标题",
    },
    type: {
      type: String,
      default: "car",
    },
    dataList: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>