<template>
  <div class="page">
    <MyEchart :visible="showcdata1" :id="'socInterval'" :options="cdata1" />
  </div>
</template>
        
<script>
import MyEchart from '@/views/components/charts/echarts'
import * as echarts from "echarts";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyEchart,
  },
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      showcdata1: false,
      cdata1: {
        title: {
          text: "%",
        },
        legend: {
          right: "3%",
          data: ["预测", "实际"],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
        },

        yAxis: {
          type: "value",
          splitLine: false,
        },
        series: [
          {
            type: "line",
            smooth: true,
            name: "预测",
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0.1,
                  color: "#B8FF7E", // 0% 处的颜色
                },
                {
                  offset: 0.9,
                  color: "#B8FF7E", // 100% 处的颜色
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: "#B8FF7E", //改变折线点的颜色
                // lineStyle: {
                // 	color: '#B8FF7E' //改变折线颜色
                // }
              },
            },
          },
          {
            type: "line",
            smooth: true,
            name: "实际",
            lineStyle: {
              width: 2,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0.1,
                  color: "#36A1FF", // 0% 处的颜色
                },
                {
                  offset: 0.9,
                  color: "#36A1FF", // 100% 处的颜色
                },
              ]),
            },
            itemStyle: {
              normal: {
                color: "#36A1FF", //改变折线点的颜色
                // lineStyle: {
                // 	color: '#B8FF7E' //改变折线颜色
                // }
              },
            },
          },
        ],
      },
      query: query,
    };
  },
  mounted() {
    this.getBattery24hours();
  },
  methods: {
    //获取电池寿命预测
    getBattery24hours() {
      let params = {
        enCode: "per_vehicle-电池寿命预测",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            console.log(res);
            let data = [];
            let xAxis = [];
            let data1 = [];
            res.forEach((item) => {
              xAxis.push(item.future_year);
              data.push(item.soh);
              data1.push(item.soh_actual);
            });
            this.cdata1.xAxis.data = xAxis;
            this.cdata1.series[0].data = data;
            this.cdata1.series[1].data = data1;
            this.showcdata1 = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
        
        <style lang="scss" scoped>
@import "../scss/page.scss";
</style>