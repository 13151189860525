<template>
  <div class="page mt-50">
    <div class="target flex align-items justify-content-evenly">
      <div class="flex flex-direction align-items">
        <!-- <img class="itemImg" src="~@/assets/images/vehiclesType/Carbonsort-1.png" alt="" /> -->
        <img src="~@/assets/images/vehiclesType/month-icon-1.png" alt="" />
        <img
          class="mt_75"
          src="~@/assets/images/vehiclesType/month-icon-bottom.png"
          alt=""
        />
      </div>
      <div class="item_center">
        <div class="titleFont">本月平均能耗</div>
        <div>
          <span class="number">{{
            stationData.cur_month_energy_consumption
          }}</span>
          <span class="uuid">度/km</span>
        </div>
      </div>
      <div class="item_box flex align-items">
        <div>
          <img
            v-if="
              Number(stationData.compare_last_month_energy_consumption * 100) >=
              0
            "
            class="monthImg"
            src="~@/assets/images/vehiclesType/Vector.png"
            alt=""
          />
          <img
            v-if="
              Number(stationData.compare_last_month_energy_consumption * 100) <
              0
            "
            class="monthImg"
            src="~@/assets/images/vehiclesType/Vector1.png"
            alt=""
          />
        </div>
        <div class="flex flex-direction align-items">
          <span class="MonthNumber"
            >{{
              Number(
                stationData.compare_last_month_energy_consumption * 100
              ).toFixed(0) | removeSign
            }}%</span
          >
          <span class="lastMonth">较上月</span>
        </div>
      </div>
    </div>
    <div class="target flex align-items justify-content-evenly">
      <div class="flex flex-direction align-items">
        <img src="~@/assets/images/vehiclesType/month-icon-2.png" alt="" />
        <img
          class="mt_75"
          src="~@/assets/images/vehiclesType/month-icon-bottom.png"
          alt=""
        />
      </div>
      <div class="item_center">
        <div class="titleFont">本月平均度电里程</div>
        <div>
          <span class="number">{{
            stationData.cur_month_mileage_per_kwh
          }}</span>
          <span class="uuid">km/度</span>
        </div>
      </div>
      <div class="item_box flex align-items">
        <div>
          <img
            v-if="
              Number(stationData.compare_last_month_mileage_per_kwh * 100) >= 0
            "
            class="monthImg"
            src="~@/assets/images/vehiclesType/Vector.png"
            alt=""
          />
          <img
            v-if="
              Number(stationData.compare_last_month_mileage_per_kwh * 100) < 0
            "
            class="monthImg"
            src="~@/assets/images/vehiclesType/Vector1.png"
            alt=""
          />
        </div>
        <div class="flex flex-direction align-items">
          <span class="MonthNumber"
            >{{
              Number(
                stationData.compare_last_month_mileage_per_kwh * 100
              ).toFixed(0) | removeSign
            }}%</span
          >
          <span class="lastMonth">较上月</span>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    //   myEchart,
  },
  filters: {
    removeSign(value) {
      if (typeof value === "string" || typeof value === "number") {
        value = value.toString();
        return value.replace(/[+-]/, "");
      }
      return value;
    },
  },
  props: {
    vin: {
      type: String,
      default: "LMEWWG2R2NEK00047",
    },
  },
  data() {
    let { query } = this.$route;
    return {
      stationData: {},
      query: query,
    };
  },
  mounted() {
    this.getDouble();
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getDouble();
      },
    },
  },
  methods: {
    // 双碳指标
    getDouble() {
      let params = {
        enCode: "per_vehicle-双碳指标",
        "@VEHICLE_VIN": this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            console.log(res, "双碳指标");
            this.stationData = res[0] || {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
      
<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.target {
  width: 100%;
  height: 50%;
  .item_center {
    width: 210px;
    height: 100%;
    .monthImg {
    }
    .titleFont {
      font-size: $FontSize26;
    }
    .number {
      font-size: $FontSize60;
      color: $FontColor;
      @include ffamily-DS;
    }
    .uuid {
      margin-left: 10px;
      font-size: $FontSize20;
    }
  }
  .monthImg {
    margin-right: 20px;
  }
  .lastMonth {
    font-size: $FontSize24;
  }
  .MonthNumber {
    font-size: $FontSize36;
    color: #ffd15c;
    @include ffamily-DS;
  }
}

.itemImg {
  animation: breath_light 3s ease infinite;
  margin-top: -40px;
}
</style>