<template>
	<div class="page">
		<MyEchart :visible="showcdata3" :id="'changeMiles'" :options="cdata3" />
	</div>
</template>

<script>
	import MyEchart from '@/views/components/charts/echarts'
	import * as echarts from "echarts";
	import {
		dataInterface
	} from "@/api/dataInterfaceApi";
	export default {
		components: {
			MyEchart,
		},
		props: {
			vin: {
				type: String,
				default: 'LMEWWG2R2NEK00047'
			}
		},
		data() {
			let {
				query
			} = this.$route;
			return {
				showcdata3: false,
				// title:"行驶里程",
				cdata3: {
					title: {
						text: "km",
					},
					backgroundColor: "rgba(0, 0, 0, 0.1)",
					legend: {
						hidden: false,
					},
					xAxis: {
						type: "category",
						boundaryGap: false,
					},
					yAxis: {
						type: "value",
						splitLine: false,
					},
					// legendData: [
					//   {
					//     // name:"新增数",
					//     icon: "roundRect",
					//   },
					//   {
					//     // name:"活跃数",
					//     icon: "roundRect",
					//   },
					// ],
					series: [],
				},
				query: query,
			};
		},
		watch: {
			vin: {
				immediate: true,
				deep: true,
				handler(newValue, oldValue) {
					// 在 propValue 值发生变化时触发，包括初始化时
					console.log("propValue 值发生变化:", newValue, oldValue);
					this.vin = newValue;
					// this.showcdata3 = false
					// this.$nextTick(() =>{
					// this.getBattery3();
					// });
				},
			},
		},
		mounted() {
			this.getBattery3();
		},
		methods: {
			getBattery3() {
				let params = {
					enCode: "per_vehicle-行驶里程-全国车辆均值",
					"@VEHICLE_VIN": this.query.newVehicleNum || this.vin,
				};
				dataInterface
					.dataFaceApi(params)
					.then((res) => {
						console.log(res, 'per_vehicle-行驶里程-全国车辆均值per_vehicle-行驶里程-全国车辆均值')
						if (res) {

							let series1 = {
								name: "车辆行驶里程均值",
								type: "line",
								stack: "Total",
								smooth: true,
								lineStyle: {
									width: 2,
									color: "#3689E3",
								},
								showSymbol: false,
								emphasis: {
									focus: "series",
								},
								itemStyle: {
									normal: {
										color: "#3689E3", //改变折线点的颜色
									},
								},
								legend: {
									// top: "50%",
									// bottom: 0,
									left: "right",
									orient: "vertical",
									itemWidth: 10,
									itemHeight: 10,
									textStyle: {
										color: "#fff",
									},
								},
								areaStyle: {
									opacity: 0.8,
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: "#0C3963",
										},
										{
											offset: 1,
											color: "rgba(12, 57, 99,0)",
										},
									]),
								},
							};
							let series2 = {
								name: "行驶里程",
								type: "line",
								stack: "Total",
								smooth: true,
								lineStyle: {
									width: 2,
									color: "#73E7AD",
								},
								showSymbol: false,
								emphasis: {
									focus: "series",
								},
								itemStyle: {
									normal: {
										color: "#73E7AD", //改变折线点的颜色
									},
								},
								areaStyle: {
									opacity: 0.8,
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: "#298E66",
										},
										{
											offset: 1,
											color: "rgba(41, 142, 102,0)",
										},
									]),
								},
							};
							let data1 = [];
							let data2 = [];
							let xAxis = [];
							res.forEach((item) => {
								xAxis.push(item.cur_date);
								data1.push(item.driver_mileage);
								data2.push(item.avg_diver_mileage);
							});
							this.cdata3.xAxis.data = xAxis;
							series1.data = data2;
							this.cdata3.series.push(series1);
							series2.data = data1;
							this.cdata3.series.push(series2);
							this.showcdata3 = true;
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "../scss/page.scss";
</style>