<template>
  <div :class="['vehicles vehiclesPage', vehicleType == '商用车' || '' ? 'Commercial' : 'Passenger']">
    <MyHeader />
    <div class="page-left flex justify-content-between">
      <div class="flex flex-direction page-left-demo">
        <MyTitle title="运营指标" />
        <div class="page-part mt-10">
          <MyTitle title="充换电次数" type="2" />
          <part1 :vin="vin"/>
        </div>
        <div class="page-part mt-10">
          <MyTitle title="行驶里程" type="2" />
          <part2 :vin="vin"/>
        </div>
        <div class="page-part mt-10">
          <MyTitle title="SOC值区间" type="2" />
          <part3 :vin="vin"/>
        </div>
      </div>
      <div class="flex flex-direction page-left-demo">
        <MyTitle title="双碳指标" />
        <div class="page-part mt-10">
          <part4 :vin="vin"/>
        </div>
        <div class="page-part mt-10">
          <MyTitle title="平均能耗趋势" type="2" />
          <part5 :vin="vin"/>
        </div>
        <div class="page-part mt-10">
          <MyTitle title="平均度电里程" type="2" />
          <part6 :vin="vin"/>
        </div>
      </div>
    </div>
    <div class="page-center">
      <div class="page-center-flashing" v-show="vehicleType == '商用车' || ''">
        <!-- <img src="@/assets/images/vehiclesType/flashing.png" alt=""> -->
        <part13 :vin="vin"/>
      </div>
      <div :class="['page-center-model-1', vehicleType == '商用车' || '' ? 'page-center-model-1' : 'page-center-model-1-1']">
        <part10 :vin="vin"/>
      </div>
      <div  :class="['page-center-model-2', vehicleType == '商用车' || '' ? 'page-center-model-2' : 'page-center-model-2-1']">
        <part12 :vin="vin"/>
      </div>
      <div :class="['page-center-model-3', vehicleType == '商用车' || '' ? 'page-center-model-3' : 'page-center-model-3-1']">
        <div class="battery-box flex justify-content align-items">
          <div
            class="gobatter point pointer flex justify-content align-items"
            @click="goBattery"
          >
            电池监控
            <img
              class="location"
              src="@/assets/images/vehiclesType/arrow_small.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        :class="['page-center-model-4', vehicleType == '商用车' || '' ? 'page-center-model-4' : 'page-center-model-4-1']"
        v-show="singleData.vehicle_state == '在线'"
      ></div>
      <div
        :class="['page-center-model-5', vehicleType == '商用车' || '' ? 'page-center-model-5' : 'page-center-model-5-1']"
        v-show="singleData.vehicle_state == '在线'"
      ></div>
      <div
      :class="['page-center-model-6', vehicleType == '商用车' || '' ? 'page-center-model-6' : 'page-center-model-6-1']"
        v-show="singleData.vehicle_state == '离线'"
      ></div>
      <div
      :class="['page-center-model-7', vehicleType == '商用车' || '' ? 'page-center-model-7' : 'page-center-model-7-1']"
        v-show="singleData.vehicle_state == '离线'"
      ></div>
      <div
      :class="['page-center-model-8', vehicleType == '商用车' || '' ? 'page-center-model-8' : 'page-center-model-8-1']"
        v-show="singleData.vehicle_state == '在线' ? true : false"
      ></div>
      <!-- <div class="page-center-model-9">
        <div class="zImg"></div>
        <div class="turntableBox flex flex-direction align-items">
          <div class="turntableNumber">78</div>
          <div class="turntableText">km/h</div>
        </div>
      </div> -->
      <!-- <div class="page-center-model-10">
        <div class="voltage flex flex-direction align-items">
          <div class="currentText">实时电压</div>
          <div>
            <span class="currentNumber">12</span>
            <span class="uuid">V</span>
          </div>
        </div>
      </div>
      <div class="page-center-model-11">
        <div class="current flex flex-direction align-items">
          <div class="currentText">实时电流</div>
          <div>
            <span class="currentNumber">12</span>
            <span class="uuid">A</span>
          </div>
        </div>
      </div> -->
      <div class="page-center-model-12">
          <part14 :vin="vin"/>
      </div>
      <div class="page-center-bottom">
        <part11 :vin="vin"/>
      </div>
    </div>
    <div class="page-right flex flex-direction">
      <MyTitle title="电池数据" />
      <div class="page-part mt-10">
        <part7 :vin="vin"/>
      </div>
      <div class="page-part mt-10">
        <part8 :vin="vin"/>
      </div>
      <div class="page-part mt-10">
        <MyTitle title="电池寿命预测" type="2" />
        <part9 :vin="vin"/>
      </div>
    </div>
  </div>
</template>

<script>
import MyTitle from "@/views/components/MyTitle.vue";
import MyHeader from "@/views/components/MyHeader.vue";
import part1 from "../components/vehiclestypePart/Part_1.vue";
import part2 from "../components/vehiclestypePart/Part_2.vue";
import part3 from "../components/vehiclestypePart/Part_3.vue";
import part4 from "../components/vehiclestypePart/Part_4.vue";
import part5 from "../components/vehiclestypePart/Part_5.vue";
import part6 from "../components/vehiclestypePart/Part_6.vue";
import part7 from "../components/vehiclestypePart/Part_7.vue";
import part8 from "../components/vehiclestypePart/Part_8.vue";
import part9 from "../components/vehiclestypePart/Part_9.vue";
import part10 from "../components/vehiclestypePart/Part_10.vue";
import part11 from "../components/vehiclestypePart/Part_11.vue";
import part12 from "../components/vehiclestypePart/Part_12.vue";
import part13 from "../components/vehiclestypePart/Part_13.vue";
import part14 from "../components/vehiclestypePart/Part_14.vue";
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {
    MyTitle,
    MyHeader,
    part1,
    part2,
    part3,
    part4,
    part5,
    part6,
    part7,
    part8,
    part9,
    part10,
    part11,
    part12,
    part13,
    part14
  },
  data() {
    let { query } = this.$route;
    return {
      timer: null,
      timer1: null,
      singleData: {
        vehicle_state: "离线",
      },
      query: query,
      vehicleType: '商用车',
      vin: 'LMEWWG2R2NEK00047'
    };
  },
  created() {
    console.log(this.query.newVehicleNum ? true : false, 'true还是false')
    if(!this.query?.newVehicleNum){
      this.getVin()
    }
  },
  methods: {
    goBattery() {
      this.$router.push({
        path: "/batterypack-home",
        query: {
          batteryId: this.query.newVehicleNum  || this.vin,
        },
      });
    },
    getSingleCar() {
      let params = {
        enCode: "per_vehicle-dcsszb",
        "@vin": this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          // console.log(res, "离线");
          if (res) {
            this.singleData = res[0] ? res[0] : {};
          }
          // setTimeout(() => {
          //   this.getSingleCar();
          // }, 30000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVin(){
      let params = {
        enCode: "per_vehicle-dc",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "vin");
          this.vin = res[0].vehicle_vin || 'LMEWWG2R2NEK00047'
          this.getSingleCar()
          // this.$bus.$emit('vin', this.vin);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.vehicleType = this.query?.vehicleType || '商用车'
    this.getSingleCar();
    this.timer = setTimeout(() => {
      this.$router.push({
        path: "batterypack-home",
        query: {
          batteryId: this.query.newVehicleNum  || this.vin,
        },
      });
    }, 1000 * 30);
    this.timer1 = setInterval(() => {
      this.getSingleCar();
    }, 1000 * 10);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer1);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
$HeaderHeight: 188px;

.vehicles {
  padding: 0 50px;
  color: #fff;
  .left-p1 {
    width: 455px;
  }
}


.Commercial,.Passenger,.vehiclesPage {
  // display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  // padding: 0 65px;
  // box-sizing: border-box;
  .page-left,
  .page-right,
  .page-center {
    // position: absolute;
    margin-top: $HeaderHeight;
    height: calc(100% - #{$HeaderHeight});
    // z-index: 10;
  }
  .page-left,
  .page-right {
    // background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    .page-part {
      width: 100%;
      height: 420px;
      // background: orange;
    }
  }
  .page-left {
    width: 1180px;
    // position: absolute;
    // left: 0px;
    // background-color: red;
    .page-left-demo {
      width: 48.5%;
      height: 100%;
      // background: orange;
    }
  }
  .page-right {
    width: 584px;
    // position: absolute;
    // right: 0px;
    // background-color: skyblue;
  }
  .page-center {
    flex: 1;
    // background: url('~@/assets/images/vehiclesType/Commercial-car.png') no-repeat;
    // background-size: 100% 100%;
    .page-center-flashing {
      width: 163px;
      height: 459px;
      position: fixed;
      top: 444px;
      left: 2178px;
      background: url("~@/assets/images/vehiclesType/flashing.png") no-repeat;
      background-size: 110% 120%;
      animation: breath_light 3s ease infinite;
    }
    .page-center-model-1 {
      position: fixed;
      top: 293px;
      left: 2283px;
    }

    .page-center-model-1-1 {
      position: fixed;
      top: 213px;
      left: 2204px;
    }

    .page-center-model-2 {
      position: fixed;
      top: 1000px;
      left: 1345px;
    }
    .page-center-model-2-1 {
      position: fixed;
      top: 894px;
      left: 1345px;
      z-index: 1;
    }
    .page-center-model-3 {
      position: fixed;
      top: 800px;
      left: 2300px;
    }
    .page-center-model-3-1 {
      position: fixed;
      top: 950px;
      left: 2230px;   
    }
    .page-center-model-3-1::before{
      position: fixed;
      top: 906px;
      left: 2120px;
      width: 200px;
      height: 200px;
      content: '';
      background: url("~@/assets/images/vehiclesType/line-4.png") no-repeat;
    }
    .page-center-model-4 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 891px;
      left: 1820px;
      background: url("~@/assets/images/vehiclesType/wheelsImg.png") no-repeat;
    }
    .page-center-model-4-1 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 791px;
      left: 1661px;
      background: url("~@/assets/images/vehiclesType/wheelsImg.png") no-repeat;
    }

    .page-center-model-5 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 891px;
      left: 2542px;
      background: url("~@/assets/images/vehiclesType/wheelsImg.png") no-repeat;
    }
    .page-center-model-5-1 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 791px;
      left: 2435px;
      background: url("~@/assets/images/vehiclesType/wheelsImg.png") no-repeat;
    }

    .page-center-model-6 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 891px;
      left: 1820px;
      background: url("~@/assets/images/vehiclesType/wheels.png") no-repeat;
    }

    .page-center-model-6-1 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 791px;
      left: 1661px;
      background: url("~@/assets/images/vehiclesType/wheels.png") no-repeat;
    }

    .page-center-model-7 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 891px;
      left: 2542px;
      background: url("~@/assets/images/vehiclesType/wheels.png") no-repeat;
    }

    .page-center-model-7-1 {
      width: 202px;
      height: 202px;
      position: fixed;
      top: 791px;
      left: 2435px;
      background: url("~@/assets/images/vehiclesType/wheels.png") no-repeat;
    }

    .page-center-model-8 {
      width: 1000px;
      height: 143px;
      position: fixed;
      top: 1085px;
      left: 1810px;
      background: url("~@/assets/images/vehiclesType/road.png") no-repeat;
      opacity: 0.3;
    }
    .page-center-model-8-1 {
      width: 1000px;
      height: 143px;
      position: fixed;
      top: 980px;
      left: 1700px;
      background: url("~@/assets/images/vehiclesType/road.png") no-repeat;
      opacity: 0.3;
    }
    .page-center-model-9 {
      position: fixed;
      top: 600px;
      left: 2510px;
      width: 254px;
      height: 254px;

      .zImg {
        width: 100%;
        height: 100%;
        background: url("~@/assets/images/vehiclesType/turntable.png") no-repeat;
        background-size: 100% 100%;
        animation: rotate 2s linear infinite;
      }
      .turntableBox {
        position: absolute;
        top: 90px;
        left: 3px;
        width: 100%;
        height: 100%;
        .turntableNumber {
          font-size: $FontSize32;
          font-weight: bold;
          @include ffamily-DS;
        }
        .turntableText {
          font-size: $FontSize18;
        }
      }
    }
    // .zImg{

    // }
    .page-center-model-10 {
      position: fixed;
      top: 685px;
      left: 2910px;
      width: 133px;
      height: 133px;
      // background: orange;
      animation: Beating 1.2s ease infinite;
      background: url("~@/assets/images/vehiclesType/voltage.png") no-repeat;
      background-size: 100% 100%;
      .voltage {
        position: absolute;
        top: 44px;
        left: 40px;
      }
    }
    .page-center-model-11 {
      position: fixed;
      top: 885px;
      left: 2810px;
      width: 133px;
      height: 133px;
      animation: Beating 1.2s ease infinite;
      background: url("~@/assets/images/vehiclesType/current.png") no-repeat;
      background-size: 100% 100%;
      .current {
        position: absolute;
        top: 44px;
        left: 40px;
      }
    }
    .page-center-model-12{
      position: fixed;
      top: 373px;
      left: 2900px;
      width: 215px;
      height: 450px;
    }
    .currentNumber {
      font-size: $FontSize24;
      font-weight: bold;
      @include ffamily-DS;
    }
    .uuid {
      font-size: $FontSize20;
      font-weight: bold;
    }
    .currentText {
      font-size: 14px;
    }
    .page-center-bottom {
      position: absolute;
      bottom: 5%;
      margin-left: 150px;
      width: 1723px;
      height: 218px;
    }
  }
}

.Commercial{
  background: url("~@/assets/images/vehiclesType/Commercial-1.png") no-repeat;
  background-size: 100% 100%;
}

.Passenger{
  background: url("~@/assets/images/vehiclesType/Commercial-2.png") no-repeat;
  background-size: 100% 100%;
}

.battery-box {
  width: 190px;
  height: 55px;
  background: #3c828b;

  .gobatter {
    font-size: 26px;
    .location {
      width: 16px;
      height: 16px;
      margin-left: 10px;
      margin-top: -3px;
    }
  }
}
</style>