<template>
  <div class="model">
    <div class="model-2 flex justify-content">
      <div class="item flex flex-direction align-items justify-content-between">
        <div class="title-Name">30天行驶天数</div>
        <div><span class="number">{{ operation_days }}</span>&nbsp;<span class="uuid">天</span></div>
      </div>
      <div class="item flex flex-direction align-items justify-content-between">
        <div class="title-Name">日均行驶时长</div>
        <div><span class="number">{{ exchange_interval_time }}</span>&nbsp;<span class="uuid">小时</span></div>
      </div>
    </div>
    <img class="line-2" src="@/assets/images/vehiclesType/line-2.png" alt="" />
  </div>
</template>
      
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  components: {},
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      operation_days: "", //运营天数
      exchange_interval_time: "", //换电时长均值
      query: query,
      timer : null
    };
  },
  mounted() {
    this.getDayAndTime()
    this.timer = setInterval(() =>{
      this.getDayAndTime()
    },10 * 1000)
  },
  methods: {
    //获取运营天数时长
    getDayAndTime() {
      let params = {
        //   "enCode": "per_vehicle-运营天数-换电时长均值",
        enCode: "per_vehicle-30天运营天数-日均运营时长",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (res) {
            this.exchange_interval_time =
              (res[0] && res[0].daily_operating_time) || "-";
            this.operation_days = (res[0] && res[0].operation_days) || "-";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>
      
<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
@import "../scss/page.scss";
.model {
  width: 362px;
  height: 140px;
  background: url("~@/assets/images/vehiclesType/model-2.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .model-2 {
    padding: 20px 0;
  }
  .item {
    width: 150px;
    height: 100px;
    .title-Name {
      font-size: $FontSize20;
      background: #00bab8;
      padding: 10px;
    }
    .number {
      font-size: $FontSize45;
      @include ffamily-DS;
    }
    .uuid{
      font-size: $FontSize26;
    }
  }
  .line-2 {
    position: absolute;
    bottom: 50px;
    right: -128px;
  }
}
</style>