<template>
  <div class="page">
    <div class="soc flex flex-direction align-items">
      <!-- <div class="socNumber">{{ singleData.cur_soc ? singleData.cur_soc : "-" }}%</div> -->
      <div class="socNumber">
        <animate-number
            from="0"
            :to="Number(singleData.cur_soc)"
            :key="Number(singleData.cur_soc)"
            class=""
          ></animate-number>%
      </div>
      <div class="socName">SOC</div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route
    return {
      singleData: {
        cur_soc: 0
      }, //单车信息位置车牌soc 状态
      query: query,
      timer: null
    };
  },
  mounted() {
    this.getSingleCar();
    this.timer = setInterval(() =>{
      this.getSingleCar()
    },10 * 1000)
  },
  methods: {
    getSingleCar() {
      let params = {
        enCode: "per_vehicle-当前省市-车牌-状态-当前SOC",
        "@VEHICLE_VIN":
          this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, '当前SOC')
          if (res) {
            this.singleData = res[0].cur_soc ? res[0] : {};
          }
          // setTimeout(() => {
          //   this.getSingleCar();
          // }, 30000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/page.scss";
@import "@/assets/scss/comm.scss";
.page {
  position: relative;
  .soc {
    position: absolute;
    top: 48%;
    left: 32%;
    .socNumber {
      color: $FontColor1;
      font-size: $FontSize48;
      @include ffamily-DS;
    }
    .socName {
      font-size: $FontSize20;
    }
  }
}
</style>