<template>
  <div class="page flex flex-direction justify-content-between" v-if="list.vehicle_state == '在线'">
    <div class="page-1 flex flex-direction">
      <div class="number">{{ Number(list.speed).toFixed(0) || '-' }}</div>
      <!-- <animate-number
        from="0"
        :to="list.speed"
        :key="list.speed"
        class="number"
      ></animate-number> -->
      <div class="label">时速(km/h)</div>
    </div>
    <div class="page-2 flex flex-direction">
      <div class="number">{{ Number(list.current).toFixed(0) || '-' }}</div>
      <!-- <animate-number
        from="0"
        :to="list.current"
        :key="list.current"
        class="number"
      ></animate-number> -->
      <div class="label">实时电流(A)</div>
    </div>
  </div>
</template>

<script>
import { dataInterface } from "@/api/dataInterfaceApi";
export default {
  props: {
    vin: {
      type: String,
      default: 'LMEWWG2R2NEK00047'
    }
  },
  data() {
    let { query } = this.$route;
    return {
      list: {
        speed: 0,
        current: 0
      },
      query: query,
      timer: null,
    };
  },
  mounted() {
    this.getData();
    this.timer = setInterval(() => {
      this.getData();
    },2000);
  },
  watch: {
    vin: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        // 在 propValue 值发生变化时触发，包括初始化时
        console.log("propValue 值发生变化:", newValue, oldValue);
        this.vin = newValue;
        // this.showcdata1 = false
        // this.$nextTick(() =>{
        //     this.getBattery1();
        // });
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      let params = {
        enCode: "per_vehicle-dcsszb",
        "@vin": this.query.newVehicleNum || this.vin,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          console.log(res, "单车实时指标");
          this.list = res[0] || {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/comm.scss";
.page {
  height: 100%;
  width: 100%;
}
.page-1,
.page-2 {
  width: 213px;
  height: 183px;
  background: skyblue;
}
.page-1 {
  background: url("~@/assets/images/vehiclesType/Speed.png") no-repeat;
  background-size: 100% 100%;
}
.page-2 {
  background: url("~@/assets/images/vehiclesType/current.png") no-repeat;
  background-size: 100% 100%;
}

.number {
  width: 100%;
  height: 100px;
  // background: red;
  font-size: $FontSize64;
  text-align: center;
  margin-top: 40px;
  @include ffamily-DS;
}
.label {
  width: 100%;
  height: 100px;
  line-height: 5px;
  // background: orange;
  font-size: $FontSize24;
  text-align: center;
}
</style>